import React, { Fragment, useContext, useEffect } from "react"
import { navigate } from "gatsby"

import CartLayout from "layout/CartLayout"
import Container from "../Layout/Container"
import ButtonGroup from "../Elements/ButtonGroup"
import Search from "../Search/Search"
import AddMedicineModal from "./AddMedicineModal"

import { AppContext } from "../../context/AppContext"
import { isMedicineAlreadyInCart } from "./services/cart"

const Epharmacy = ({
  pageContext,
  cartContainer,
  data,
  lookupKeys,
  medEnsureState,
  medEnsureDispatch,
}) => {
  const { dispatch } = useContext(AppContext)

  const handleOnSelect = async (item) => {
    if (isMedicineAlreadyInCart(item, medEnsureState.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${item.DrugName} already in cart`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light`,
          content: (
            <AddMedicineModal
              addToCart={addMedicineToCart}
              medicine={item}
              dispatch={dispatch}
            />
          ),
        },
      })
  }

  const addMedicineToCart = async (item, qty, intake) => {
    const medicineList = [...medEnsureState.medicines]
    medicineList.push({ ...item, qty: qty, intake: intake })
    await medEnsureDispatch({
      type: "SAVE_MEDICINES",
      payload: medicineList,
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.DrugName} to cart`,
        color: "success",
      },
    })
    medEnsureDispatch({
      type: "SAVE_SELECTED_MEDICINE",
      payload: null,
    })
    medEnsureDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...medEnsureState,
        medicines: medicineList,
        selectedMedicine: null,
      },
    })
  }

  const searchComponentProps = {
    placeholder: "Metformin",
    label: "",
    dataSource: {
      type: "graphql",
      data: data,
      lookupKeys: lookupKeys,
    },
    onSelect: handleOnSelect,
    withIcon: true,
    message: {
      color: "warning",
      content: (
        <span>
          Medicines covered may be updated to ensure availability of essential
          medicines.{" "}
          <strong>Value brands will be dispensed when available.</strong>
        </span>
      ),
    },
  }

  useEffect(() => {
    if (medEnsureState?.selectedMedicine) {
      handleOnSelect(medEnsureState?.selectedMedicine)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    medEnsureDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <CartLayout
        isCentered
        desktop={10}
        fullhd={8}
        cartContainer={cartContainer}
      >
        <div className="is-hidden-mobile">
          <Search {...searchComponentProps} />
        </div>
        <div className="is-hidden-desktop is-hidden-tablet">
          <div
            onClick={() => {
              navigate(`${pageContext.mobileSearchPath}`)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(`${pageContext.mobileSearchPath}`)
              }
            }}
            role="button" // Add a role attribute
            tabIndex={0} // Add a tabIndex for keyboard accessibility
          >
            <Search {...searchComponentProps} />
          </div>
        </div>
      </CartLayout>
      <Container mobile={12} tablet={10} desktop={6} isCentered>
        <ButtonGroup
          back={{
            link: pageContext.backPath,
          }}
          next={{
            label: `Next: ${pageContext.module.next}`,
            link: pageContext.nextPath,
            type: "next",
            disabled:
              medEnsureState?.medicines?.length === 0 ||
              medEnsureState?.documents?.length === 0,
          }}
        />
      </Container>
    </Fragment>
  )
}

export default Epharmacy
