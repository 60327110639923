import React, { useContext } from "react"

import Layout from "layout"
import MedEnsureCart from "../../Cart"
import Epharmacy from "../../../Epharmacy"
import { medEnsureSteps } from "../../utils/medEnsureSteps"
import { MedEnsureContext } from "../../MedEnsureContext/MedEnsureContext"
import useSitelMedensureProducts from "../../Home/hooks/useSitelMedensureProducts"

const MedEnsureOrder = ({ pageContext, location }) => {
  const data = useSitelMedensureProducts()

  let dataMap = data.allAirtableallSitelMeProducts.nodes.map(
    (node) => node.data
  )

  const { medEnsureState, medEnsureDispatch } = useContext(MedEnsureContext)

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      currentStep={2}
      totalSteps={4}
      pageContext={pageContext}
      steps={medEnsureSteps}
      location={location}
      path={pageContext?.module.path}
    >
      <Epharmacy
        pageContext={pageContext}
        cartContainer={
          <MedEnsureCart
            open={{ documentsCard: false, medicinesCard: true }}
            medEnsureState={medEnsureState}
            medEnsureDispatch={medEnsureDispatch}
            pageContext={pageContext}
          />
        }
        data={dataMap}
        lookupKeys={["Brand", "DrugName"]}
        searchLabel="Search medicines"
        medEnsureState={medEnsureState}
        medEnsureDispatch={medEnsureDispatch}
      />
    </Layout>
  )
}

export default MedEnsureOrder
